export enum TrType {
  TRANSFER_ASSETS_TRANSACTION = "TransferAssetsTransaction",
  REGISTER_CONTRACT = "RegisterContract",
  CONTRACT_TRANSACTION = "ContractTransaction",
  CAEB_TRANSACTION = "CAEBTransaction",
}
export enum ExchangeType {
  INDIVISIBLE = "isFixed",
  DIVISIBLE = "isPrice",
}
export enum OrderStatus {
  ACTIVE = "Active",
  CANCELED = "Canceled",
  EXECUTED = "Executed",
  PENDING = "Pending",
  CLOSE_PENDING = "ClosePending",
}
