
import WindowContext from "@/components/WindowContext.vue";
import {
  defineComponent,
  defineAsyncComponent,
  computed,
  onMounted,
  onBeforeUnmount,
  watchEffect,
} from "vue";
import { useRoute } from "vue-router";
import { initStyles } from "@/core/utils/initStyles";
import { useBlockchainStore } from "@/store/blockchain";
import { storeToRefs } from "pinia";
export default defineComponent({
  name: "GausWeb",
  components: {
    WindowContext,
    DefaultLayout: defineAsyncComponent(() => import("@/layouts/default.vue")),
  },
  setup() {
    // Libs
    const route = useRoute();
    // Set window title
    const blockchainStore = useBlockchainStore();
    const { getTokenInfoByParam } = blockchainStore;
    const { currentPair } = storeToRefs(blockchainStore);

    const firstTokenInfo = computed(() =>
      getTokenInfoByParam("name", currentPair.value?.firstToken || "")
    );
    watchEffect(() => {
      document.title =
        `${
          firstTokenInfo.value?.price || 0
        } ${currentPair.value.firstToken?.toUpperCase()}/${currentPair.value.secondToken?.toUpperCase()}` ||
        document.title;
    });
    // Layout settings
    const layout = computed(() => {
      return route?.meta?.layout || "default-layout";
    });
    onMounted(() => {
      initStyles();
      window.addEventListener("resize", initStyles);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("resize", initStyles);
    });
    return {
      layout,
    };
  },
});
