import { defineStore, storeToRefs } from "pinia";
import { computed, ref } from "vue";
import {
  TokenInfoType,
  TransactionDescriptionType,
  TransactionDirectionEnum,
  TransactionInfoType,
  WalletInfoType,
  WalletStatus,
} from "@/store/types";
import RestApiWrapper from "@/core/Api";
import { useAuthStore } from "@/store/auth";
import { useBlockchainStore } from "@/store/blockchain";
import {
  addTransactionInSessionStorage,
  parseTransaction,
  parseTransactions,
} from "@/store/utils/transactions";

export const useWalletStore = defineStore("wallet", () => {
  // AUTH STORE
  const authStore = useAuthStore();
  const { keyPairs } = storeToRefs(authStore);
  // BLOCKCHAIN STORE
  const blockchainStore = useBlockchainStore();
  const { getTokenByParam } = blockchainStore;
  const { assets } = storeToRefs(blockchainStore);

  // STATE
  const wallet = ref<WalletInfoType>({
    address: "",
    balance: [],
    freeze: [],
    commission: "",
    transactions: [],
    status: WalletStatus.UNCONFIRMED,
  });

  // GETTERS
  const walletAddress = computed(() => wallet.value.address);
  const getBalanceList = computed<TokenInfoType[]>(() =>
    assets.value.map((el) => {
      const fullBalance =
        wallet.value.balance.find((item) => el.code === item.code)?.amount ?? 0;
      const freeze =
        wallet.value.freeze.find((item) => el.code === item.code)?.amount ?? 0;
      return {
        tokenDescription: el.description,
        tokenBaseName: el.name || "-",
        token: (el.fullname || "-").toUpperCase(),
        balance: fullBalance,
        balanceAvailable: +fullBalance - +freeze,
        balanceReserved: freeze,
      };
    })
  );
  const getTotalBalance = computed<number>(() =>
    wallet.value.balance.reduce(
      (totalBalance, el) => totalBalance + +el.amount,
      0
    )
  );

  // ACTIONS
  const getBalanceByTokenParam = (
    value: string,
    param: "code" | "name"
  ): { balance: number; available: number; freeze: number } => {
    const info = { balance: 0, available: 0, freeze: 0 };
    const balanceToken = wallet.value.balance.find(
      (el) =>
        (getTokenByParam("code", el.code) || {})[param] === value.toLowerCase()
    )?.amount;
    const freezeToken = wallet.value.freeze.find(
      (el) =>
        (getTokenByParam("code", el.code) || {})[param] === value.toLowerCase()
    )?.amount;
    if (!balanceToken) return info;
    else if (!freezeToken) {
      info.available = info.balance = +balanceToken;
      return info;
    }
    info.balance = +balanceToken;
    info.available = +balanceToken - +freezeToken;
    info.freeze = +freezeToken;
    return info;
  };
  const getWalletInfo = async (): Promise<void> => {
    const { result } = await RestApiWrapper.getWalletByAddress(
      keyPairs.value.public_key
    );
    wallet.value.address = keyPairs.value.public_key;
    wallet.value.balance = Array.isArray(result.balance) ? result.balance : [];
    wallet.value.freeze = Array.isArray(result.freeze) ? result.freeze : [];
    wallet.value.status =
      Array.isArray(result.tags) && result.tags.includes(WalletStatus.CONFIRMED)
        ? WalletStatus.CONFIRMED
        : WalletStatus.UNCONFIRMED;
    if (Array.isArray(result["additional-info"]))
      wallet.value.commission =
        result["additional-info"].find((info) => info.name === "commission") ||
        "";
  };
  const getWalletTransactions = async () => {
    let transactions: TransactionInfoType[] = [];
    const { result: trFromResult } =
      await RestApiWrapper.getTransactionFromWallet(keyPairs.value.public_key);
    const { result: trToResult } = await RestApiWrapper.getTransactionToWallet(
      keyPairs.value.public_key
    );
    if (trFromResult && Array.isArray(trFromResult.transactions)) {
      transactions = [
        ...parseTransactions(
          trFromResult.transactions,
          assets.value,
          TransactionDirectionEnum.SENT,
          true
        ),
      ];
    }
    if (trToResult && Array.isArray(trToResult.transactions)) {
      transactions = [
        ...transactions,
        ...parseTransactions(
          trToResult.transactions,
          assets.value,
          TransactionDirectionEnum.RECEIVED
        ),
      ];
    }
    wallet.value.transactions = transactions;
  };
  const addWalletTransaction = (
    descr: TransactionDescriptionType,
    type: TransactionDirectionEnum
  ): void => {
    const parsedTr = parseTransaction(
      {
        description: descr,
        type: "pending",
        digest: "",
        ID: "10000000000000",
        timestamp: "10000000000000",
      },
      assets.value,
      type
    );
    if (!parsedTr) return;
    addTransactionInSessionStorage(parsedTr);
    wallet.value.transactions = [parsedTr, ...wallet.value.transactions];
  };
  return {
    wallet,
    walletAddress,
    getBalanceList,
    getTotalBalance,
    getWalletInfo,
    getWalletTransactions,
    getBalanceByTokenParam,
    addWalletTransaction,
  };
});
