export const bem = (baseClass: string, modifiers: string): string[] => {
  const arrayClasses: string[] = [];
  arrayClasses.push(baseClass);
  if (modifiers)
    arrayClasses.push(
      ...modifiers
        .trim()
        .split(" ")
        .map((el) => (el.trim() ? `${baseClass}--${el}` : ""))
    );
  return arrayClasses;
};
