import { defineStore } from "pinia";
import { ref } from "vue";
export const useTradeStore = defineStore("tradeStore", () => {
  // STATE
  const isOpenSelect = ref<boolean>(false);

  // ACTIONS
  const setSelectPairState = (value: boolean): void => {
    isOpenSelect.value = value;
  };
  const openSelectPair = (): void => {
    setSelectPairState(true);
  };
  const closeSelectPair = (): void => {
    setSelectPairState(false);
  };

  return {
    isOpenSelect,
    closeSelectPair,
    openSelectPair,
    setSelectPairState,
  };
});
