import "@/components/wasm/Container.wasm";
import Container from "@/components/wasm/Container";
import "@/components/wasm/shampoox.wasm";
import Shampoox from "@/components/wasm/shampoox";
import JSZip from "jszip";

async function preloadWasmModules() {
  console.time("wasm_load_time");
  Container().then((ctr) => {
    console.timeEnd("wasm_load_time");
    let errorContainer = new ctr.Error();
    if (errorContainer.hasError) console.error(errorContainer.what);
  });
}

function createHexString(arr) {
  let result = "";
  let z;
  for (let i = 0; i < arr.length; i++) {
    let str = arr[i].toString(16);
    z = 8 - str.length + 1;
    str = Array(z).join("0") + str;
    result += str;
  }
  return result;
}

function parseHexString(str) {
  let result = [];
  while (str.length >= 8) {
    result.push(parseInt(str.substring(0, 8), 16));
    str = str.substring(8, str.length);
  }
  return result;
}

function loadContainerFile(infl, onSuccess, onError) {
  let files = infl.target.files || infl.dataTransfer.files;
  if (!files.length) return;
  let reader = new FileReader();
  /*
    reader.onloadstart = ls_e => {
      this.wallet.progress.value = 0;
      if (ls_e.lengthComputable) this.wallet.progress.show = true;
    };
    reader.onprogress = lp_e => {
      if (lp_e.lengthComputable)
        this.wallet.progress.value = parseInt(
          (lp_e.loaded / lp_e.total) * 100,
          10
        );
    };*/
  reader.onerror = (ler_e) => {
    onError(ler_e.target.error.code, ler_e.target.error.message);
  };

  reader.onload = (e) => {
    try {
      var arrayBufferNew = null;
      arrayBufferNew = e.target.result;
      let zip = new JSZip();
      zip
        .loadAsync(arrayBufferNew)
        .then(function (zip) {
          // you now have every files contained in the loaded zip
          zip
            .file("container.json")
            .async("string")
            .then((data) => {
              let obj = JSON.parse(data);
              onSuccess(obj);
            })
            .catch((e) => {
              onError(e.what);
            });
        })
        //.catch(_errFormat=> {
        .catch(() => {
          // wrong format or old container type
          try {
            let obj = {
              rawdata: e.target.result,
              meta: {},
            };
            onSuccess(obj);
          } catch (e) {
            onError("Wrong format");
          }
        });
    } catch (e) {
      onError("Wrong hash");
    } finally {
      infl.target.value = null;
    }
    //this.wallet.progress.value = 100;
    //this.wallet.progress.show = false;
  };
  try {
    reader.readAsBinaryString(files[0]);
  } catch (e) {
    onError("Error opening file", e.stack);
  }
}
function parseAndOpenContiner(obj, onSuccess, onError, pincode) {
  let container_uint8array = parseHexString(obj.rawdata);
  if (pincode) {
    Container().then((ctr) => {
      let errorContainer = new ctr.Error();
      let keys = null;
      try {
        keys = ctr.open_container(
          container_uint8array,
          String(pincode),
          errorContainer
        );
      } catch (err) {
        if (onError) onError("wrong file format");
        return;
      }
      if (keys === undefined) {
        if (onError) onError("Unable to load file: " + errorContainer.what);
        return;
      } else {
        //success
        onSuccess(keys);
        // if ('eth_wallet' in obj.meta) {
        //     this.wallet.eth = obj.meta.eth_wallet
        //     this.wallet.eth_input_field = this.wallet.eth
        // }
        // this.wallet.keyPair = keys;
        // this.wallet.address = keys.public_key;
        //WalletSession.createSession(this.wallet.keyPair)
      }
    });
  }
}
function packContainer(container, meta = {}) {
  let obj = {
    rawdata: container,
    meta: meta,
  };
  return JSON.stringify(obj);
}

function createPhrase(onSuccess, onError) {
  // GENERATE MNEMONIC PHRASE BIP39
  Shampoox().then((api) => {
    const err = new api.Error();
    if (err.hasError) {
      onError("Shampoox error " + err.what);
    }
    const phrase = api.Bip39.generateMnemonic().split(" ");
    if (phrase) {
      onSuccess(phrase);
    } else {
      onError("Shampoox error");
    }
  });
}

function createWallet(
  { userPassword, mnemonicPhrase, nameFile },
  onSuccess,
  onError
) {
  //CREATE CONTAINER FROM MNEMONIC
  console.log("precreate");
  Shampoox().then((api) => {
    const err = new api.Error();
    if (err.hasError) {
      onError("Container error");
    }
    let keyPairs;
    if (mnemonicPhrase.length > 0) {
      mnemonicPhrase = String(mnemonicPhrase);
      keyPairs = new api.Pair.fromMnemonic(mnemonicPhrase, "", err);
      // if user skipped mnemonic phrase step
      // make a random keypair
    } else {
      keyPairs = new api.Pair.random(err);
      if (err.hasError) {
        onError("Keypair generation error");
      }
    }
    Container().then((ctr) => {
      const errorContainer = new ctr.Error();
      const container = ctr.create_container(
        keyPairs.privateKey,
        keyPairs.publicKey,
        String(userPassword),
        errorContainer
      );
      if (container === undefined) {
        onError("Error creating Container");
      } else {
        const hexString = createHexString(container);
        let packetContainer = packContainer(hexString);
        let zip = new JSZip();
        zip.file("container.json", packetContainer);
        zip
          .generateAsync({
            type: "blob",
            compression: "DEFLATE",
          })
          .then(function (content) {
            const file = new Blob([content], {
              type: "application/octet-stream",
            });
            if (window.navigator.msSaveOrOpenBlob)
              // IE10+
              window.navigator.msSaveOrOpenBlob(
                file,
                `${
                  nameFile ||
                  keyPairs.publicKey.slice(keyPairs.publicKey.length - 10)
                }.ctr`
              );
            else {
              // Others
              var a = document.createElement("a"),
                url = URL.createObjectURL(file);
              a.href = url;
              a.download = `${
                nameFile ||
                keyPairs.publicKey.slice(keyPairs.publicKey.length - 10)
              }.ctr`;
              document.body.appendChild(a);
              a.click();
              setTimeout(function () {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
              }, 0);
            }
          });
      }
    });
  });
  onSuccess();
}

export {
  createHexString,
  loadContainerFile,
  parseAndOpenContiner,
  preloadWasmModules,
  packContainer,
  createPhrase,
  createWallet,
  Container,
};
