import { ref, watch, computed } from "vue";

export const useAssetFilter = (
  hideZeroBalance: boolean,
  hideAllBalance: boolean
) => {
  // STATE
  const curHideZero = ref<boolean>(hideZeroBalance);
  const curHideAll = ref<boolean>(hideAllBalance);

  // COMPUTED
  const disabledBtn = computed<boolean>(
    () =>
      curHideAll.value === hideAllBalance &&
      curHideZero.value === hideZeroBalance
  );
  const visibleClear = computed<boolean>(
    () => curHideAll.value || curHideZero.value
  );

  // WATCH
  watch(curHideAll, (val) => {
    if (val) curHideZero.value = false;
  });
  watch(curHideZero, (val) => {
    if (val) curHideAll.value = false;
  });

  // METHODS
  const resetFilters = () => {
    curHideZero.value = curHideAll.value = false;
  };

  return {
    disabledBtn,
    curHideZero,
    curHideAll,
    resetFilters,
    visibleClear,
  };
};
