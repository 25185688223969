import { defineStore } from "pinia";
import { ref } from "vue";
import { Order } from "@/store/types";
import { useTabletNavStore } from "@/store/tabletNavigation";
import { MobileTabs } from "@/store/types/mobileNav";
export const useOrderFormStore = defineStore("orderForm", () => {
  // BLOCKCHAIN STORE
  const navStore = useTabletNavStore();
  const { setTab } = navStore;

  // STATE
  const isReversedRate = ref<boolean>(false);
  const selectedOrder = ref<Order | null>(null);

  // ACTIONS
  const setSelectedOrder = (order: Order): void => {
    selectedOrder.value = { ...order };
    setTab(MobileTabs.TRADE);
  };
  const setIsReversedRate = (value: boolean): void => {
    isReversedRate.value = value;
  };

  return {
    selectedOrder,
    isReversedRate,
    setSelectedOrder,
    setIsReversedRate,
  };
});
