const { EXPLORER_URL } = require("@/core/constants");

export const getShortAddress = (address: string): string => {
  return address.slice(0, 7) + "..." + address.slice(-7);
};
export const isValidAddress: { [key: string]: (address: string) => boolean } = {
  usdt(address: string) {
    return /^T[A-Za-z1-9]{33}$/gis.test(address);
  },
  gauss(address: string) {
    return /^[A-Za-z1-9]{49,51}$/gis.test(address);
  },
};
export const openExplorer = (time: number) => {
  const url = `${EXPLORER_URL}transaction/${time}`;
  const newWindow = window.open(url, "_blank");
  if (newWindow) {
    newWindow.focus();
  }
};
