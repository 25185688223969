import { AssetsBlockchainType, Order } from "@/store/types";
import { TrDomApi } from "@/core/types";
import { ExchangeType, OrderStatus } from "@/models/orders";
import { getRandomNumber } from "@/core/utils/number";
const {
  MAX_NUMBER_AFTER_COMMA,
  CLOSED_STORAGE_ORDERS_KEY,
  STORAGE_ORDERS_KEY,
} = require("@/core/constants");

export const parseOrders = (
  data: TrDomApi[],
  assets: AssetsBlockchainType[]
): Order[] => {
  const orders: Order[] = [];
  const seeds: string[] = [];
  if (!data) return orders;
  const closedOrders = getClosedOrdersFromSessionStorage();
  data.forEach((transaction) => {
    // skip closed orders
    if (
      closedOrders.includes(transaction.description.seed) &&
      transaction.description.status === OrderStatus.CANCELED
    ) {
      removeCloseOrderFromSessionStorage(transaction.description.seed);
      removeOrderFromClosedSessionStorage(transaction.description.seed);
    } else if (closedOrders.includes(transaction.description.seed)) return;
    seeds.push(transaction.description.seed);
    orders.push(parseOrder(transaction, assets));
  });
  // get from session storage pending order
  const sessionStorageOrders = getOrdersFromSessionStorage();
  sessionStorageOrders.forEach((order: Order) => {
    if (seeds.includes(order.seed)) {
      removeOrderFromSessionStorage(order.seed);
      return;
    }
    orders.push(order);
  });
  orders.sort((a: Order, b: Order) => b.time - a.time);
  return orders;
};

export const parseOrder = (
  {
    description,
    ID,
    ["start-desired"]: startDesired,
    ["start-offered"]: startOffered,
    ["current-offered"]: curOffered,
    ["current-desired"]: curDesired,
  }: TrDomApi,
  assets: AssetsBlockchainType[]
): Order => {
  const desired = description["desired-coin"][0];
  const offered = description["offered-coin"][0];
  const desiredAmount = startDesired || +desired.amount;
  const offeredAmount = startOffered || +offered.amount;
  const filled = curOffered ? offeredAmount - curOffered : curOffered;
  const desiredToken = assets.find(
    (el) => el.code === String(desired["asset-code"])
  )?.name;
  const offeredToken = assets.find(
    (el) => el.code === String(offered["asset-code"])
  )?.name;
  return {
    buy: desiredAmount,
    sell: offeredAmount,
    rate: desiredAmount / offeredAmount,
    type: description["exchange-type"],
    time: +ID,
    address: description["public-key"],
    filled,
    amount: offeredAmount,
    percentFilled: ((filled || 0) / offeredAmount) * 100,
    status: description.status,
    seed: description.seed,
    unique_id: description.unique_id,
    desiredToken: desiredToken!,
    offeredToken: offeredToken!,
    hash: String(getRandomNumber(0, 100000)),
    startDesired,
    startOffered,
    curOffered: curOffered || offeredAmount,
    curDesired: curDesired || desiredAmount,
  };
};

export const mergeOrdersByPrice = (
  data: Order[],
  publicKey: string
): Order[] => {
  if (!data.length) {
    return data;
  }
  const dict: { [key: string | symbol]: Order } = {};
  data.forEach((item: Order, idx: number) => {
    const { rate, curDesired, curOffered, type, address } = item;
    // skip indivisible and cur wallet order
    if (type === ExchangeType.INDIVISIBLE || address === publicKey) {
      dict[`ind-${idx}`] = { ...item };
      return;
    }
    const formatRate = rate.toFixed(MAX_NUMBER_AFTER_COMMA);
    if (!dict[formatRate]) {
      dict[formatRate] = { ...item };
      return;
    }
    dict[formatRate].curDesired = +dict[formatRate].curDesired! + +curDesired!;
    dict[formatRate].curOffered = +dict[formatRate].curOffered! + +curOffered!;
  });

  return Object.values(dict).map((value) => value);
};

export const getOrdersFromSessionStorage = (): Order[] => {
  const currentStorage = sessionStorage.getItem(STORAGE_ORDERS_KEY);
  if (!currentStorage) return [];
  return JSON.parse(currentStorage);
};
export const getClosedOrdersFromSessionStorage = (): string[] => {
  const currentStorage = sessionStorage.getItem(CLOSED_STORAGE_ORDERS_KEY);
  if (!currentStorage) return [];
  return JSON.parse(currentStorage);
};
export const addOrderInSessionStorage = (order: Order): void => {
  try {
    const currentStorage = sessionStorage.getItem(STORAGE_ORDERS_KEY);
    if (!currentStorage) {
      sessionStorage.setItem(STORAGE_ORDERS_KEY, JSON.stringify([order]));
      return;
    }
    const parsedStorage = JSON.parse(currentStorage);
    parsedStorage.unshift(order);
    sessionStorage.setItem(STORAGE_ORDERS_KEY, JSON.stringify(parsedStorage));
  } catch (e) {
    console.error(e);
  }
};
export const removeOrderFromSessionStorage = (seed: string): void => {
  try {
    const currentStorage = sessionStorage.getItem(STORAGE_ORDERS_KEY);
    if (!currentStorage) {
      return;
    }
    const parsedStorage = JSON.parse(currentStorage);

    sessionStorage.setItem(
      STORAGE_ORDERS_KEY,
      JSON.stringify(parsedStorage.filter((el: Order) => el.seed !== seed))
    );
  } catch (e) {
    console.error(e);
  }
};

export const removeCloseOrderFromSessionStorage = (seed: string): void => {
  try {
    const currentStorage = sessionStorage.getItem(STORAGE_ORDERS_KEY);
    if (!currentStorage) {
      return;
    }
    const parsedStorage = JSON.parse(currentStorage);

    sessionStorage.setItem(
      STORAGE_ORDERS_KEY,
      JSON.stringify(
        parsedStorage.filter((el: Order) => el.unique_id[0].seed !== seed)
      )
    );
  } catch (e) {
    console.error(e);
  }
};

export const addOrderInClosedSessionStorage = (seed: string): void => {
  try {
    const currentStorage = sessionStorage.getItem(CLOSED_STORAGE_ORDERS_KEY);
    if (!currentStorage) {
      sessionStorage.setItem(CLOSED_STORAGE_ORDERS_KEY, JSON.stringify([seed]));
      return;
    }
    const parsedStorage = JSON.parse(currentStorage);
    parsedStorage.unshift(seed);
    sessionStorage.setItem(
      CLOSED_STORAGE_ORDERS_KEY,
      JSON.stringify(parsedStorage)
    );
  } catch (e) {
    console.error(e);
  }
};
export const removeOrderFromClosedSessionStorage = (seed: string) => {
  try {
    const currentStorage = sessionStorage.getItem(CLOSED_STORAGE_ORDERS_KEY);
    if (!currentStorage) return;
    const parsedStorage = JSON.parse(currentStorage);
    sessionStorage.setItem(
      CLOSED_STORAGE_ORDERS_KEY,
      JSON.stringify(parsedStorage.filter((el: string) => el !== seed))
    );
  } catch (e) {
    console.error(e);
  }
};
