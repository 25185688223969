import { defineStore } from "pinia";
import { ref } from "vue";
import {
  ModalParams,
  ModalPath,
  ModalProps,
  ModalType,
} from "@/store/types/dialog";
const pathOfModals: ModalPath = {
  withdraw: "/Withdraw/WithdrawModal.vue",
  transfer: "/Transfer/TransferModal.vue",
  "asset-info": "/Asset/AssetInfoModal.vue",
  "private-key": "/PrivateKey/PrivateKeyModal.vue",
  "order-confirm": "/OrderConfirm/OrderConfirmModal.vue",
  "order-success": "/OrderSuccess/OrderSuccessModal.vue",
  "order-error": "/OrderError/OrderErrorModal.vue",
  "cancel-order": "/CancelOrder/CancelOrderModal.vue",
  "open-order": "/OpenOrder/OpenOrderModal.vue",
  "cancel-all-orders": "/CancelAllOrders/CancelAllOrdersModal.vue",
  "order-history": "/OrderHistory/OrderHistoryModal.vue",
  "pair-statistic": "/PairStatistic/PairStatisticModal.vue",
  transaction: "/Transaction/TransactionModal.vue",
  settings: "/Settings/SettingsModal.vue",
  "token-select": "/TokenSelect/TokenSelectModal.vue",
  "validation-wallet": "/WalletValidation/WalletValidationModal.vue",
};
export const useDialogStore = defineStore("dialog", () => {
  // STATE
  const isOpen = ref<boolean>(false);
  const modal = ref<ModalProps | null>(null);

  // GETTERS

  // ACTIONS
  const openDialog = (name: ModalType, params?: ModalParams): void => {
    modal.value = {
      name,
      path: pathOfModals[name],
      params: params || {},
    };
    isOpen.value = true;
  };
  const closeDialog = (): void => {
    modal.value = null;
    isOpen.value = false;
  };

  return {
    isOpen,
    modal,
    openDialog,
    closeDialog,
  };
});
