import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { AuthType, ContainerRowDataType, KeyPairsType } from "@/store/types";
import { useRouter } from "vue-router";
import { RouteName } from "@/router/types";
const { SESSION_STORAGE } = require("../core/constants");
import { sha256 } from "js-sha256";

export const useAuthStore = defineStore("auth", () => {
  const router = useRouter();
  // STATE
  const keyPairs = ref<KeyPairsType>({
    public_key: "",
    private_key: "",
  });
  const tempKeyPairs = ref<KeyPairsType>({
    public_key: "",
    private_key: "",
  });
  const passwordHashed = ref<string>("");
  const isOpened = ref<boolean>(false);
  const fileName = ref<string>("");
  const authType = ref<AuthType>(AuthType.REGISTRATION);
  const containerRowData = ref<ContainerRowDataType | null>(null);
  const password = ref<string>("");
  const phrase = ref<string[]>([]);
  const skipSeedWords = ref<boolean>(false);
  const isSeedRestore = ref<boolean>(false);

  // COMPUTED
  const isAuth = computed<boolean>(() => !!keyPairs.value.public_key);

  // ACTIONS
  const initKeyPairsFromLocalStorage = (): void => {
    const public_key = sessionStorage.getItem("public_key");
    const private_key = sessionStorage.getItem("private_key");
    if (public_key && private_key) keyPairs.value = { public_key, private_key };
  };
  const clearKeyPairs = (): void => {
    keyPairs.value = { public_key: "", private_key: "" };
    sessionStorage.removeItem("public_key");
    sessionStorage.removeItem("private_key");
    sessionStorage.removeItem("container");
  };
  const setKeyPairs = ({ public_key, private_key }: KeyPairsType): void => {
    sessionStorage.setItem("public_key", public_key);
    sessionStorage.setItem("private_key", private_key);
    keyPairs.value = { public_key, private_key };
  };
  const logout = async () => {
    clearKeyPairs();
    await router.push({ name: RouteName.HOME });
  };
  const openAuth = (): void => {
    if (isAuth.value) {
      router.push({ name: RouteName.TRADE });
      return;
    }
    if (window.innerWidth > 768) {
      isOpened.value = true;
      document.querySelector("body")?.classList.add("locked");
    } else {
      resetAuthType();
      router.push({ name: RouteName.AUTH });
    }
  };
  const resetAuthData = (): void => {
    fileName.value = "";
    containerRowData.value = null;
    password.value = "";
    phrase.value = [];
    skipSeedWords.value = false;
    authType.value = AuthType.REGISTRATION;
  };
  const closeAuth = (): void => {
    resetAuthData();
    if (window.innerWidth > 768) {
      isOpened.value = false;
      document.querySelector("body")?.classList.remove("locked");
    }
  };
  const setAuthType = (value: AuthType): void => {
    authType.value = value;
  };
  const resetAuthType = (): void => {
    authType.value = AuthType.REGISTRATION;
  };
  const setFileName = (value: string): void => {
    fileName.value = value;
  };
  const setContainerRowData = (value: ContainerRowDataType): void => {
    containerRowData.value = value;
  };
  const setPassword = (value: string): void => {
    password.value = value;
  };
  const setPasswordHashed = (value: string): void => {
    if (value === "") {
      passwordHashed.value = "";
    } else {
      passwordHashed.value = sha256(value);
      localStorage.setItem(
        SESSION_STORAGE.hashedPassword,
        passwordHashed.value
      );
    }
  };
  const initPasswordHashed = (): void => {
    const value = localStorage.getItem(SESSION_STORAGE.hashedPassword);
    if (value) passwordHashed.value = value;
  };
  const setPhrase = (value: string[]): void => {
    phrase.value = value;
  };
  const setSkipWordsStatus = (value: boolean): void => {
    skipSeedWords.value = value;
  };
  const setSeedRestoreStatus = (value: boolean): void => {
    isSeedRestore.value = value;
  };
  const setTempKeyPairs = ({ public_key, private_key }: KeyPairsType): void => {
    tempKeyPairs.value = { public_key, private_key };
  };

  return {
    passwordHashed,
    keyPairs,
    isAuth,
    isOpened,
    fileName,
    authType,
    containerRowData,
    password,
    phrase,
    skipSeedWords,
    isSeedRestore,
    tempKeyPairs,
    initKeyPairsFromLocalStorage,
    clearKeyPairs,
    logout,
    setKeyPairs,
    resetAuthType,
    openAuth,
    closeAuth,
    setAuthType,
    setFileName,
    setContainerRowData,
    setPassword,
    setPasswordHashed,
    setPhrase,
    setSkipWordsStatus,
    setSeedRestoreStatus,
    setTempKeyPairs,
    initPasswordHashed,
  };
});
