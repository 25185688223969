import messages from "../language";
import { createI18n } from "vue-i18n";
export let currentLocale = localStorage.getItem("lang");
if (!currentLocale) currentLocale = "en";
document.documentElement.setAttribute("lang", currentLocale);
export default createI18n({
  locale: currentLocale || "en", // set locale
  fallbackLocale: "en", // set fallback locale
  globalInjection: true,
  legacy: false,
  messages,
});
