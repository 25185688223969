import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView/index.vue";
import AuthView from "../views/AuthView/index.vue";
import WalletView from "../views/WalletView/index.vue";
import MarketView from "../views/MarketView/index.vue";
import TradeView from "../views/TradeView/index.vue";
import { RouteName } from "@/router/types";
import { authViewMiddleware, usersViewsMiddleware } from "@/router/middleware";
import { isAuth } from "@/core/utils/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: RouteName.HOME,
    component: HomeView,
  },
  {
    path: "/auth",
    name: RouteName.AUTH,
    component: AuthView,
    beforeEnter(to, from, next) {
      authViewMiddleware(next);
    },
  },
  {
    path: "/market",
    name: RouteName.MARKET,
    component: MarketView,
  },
  {
    path: "/wallet",
    name: RouteName.WALLET,
    component: WalletView,
    beforeEnter(to, from, next) {
      usersViewsMiddleware(next);
    },
    children: [
      {
        path: "assets",
        name: RouteName.WALLET_ASSETS,
        component: WalletView,
        beforeEnter(to, from, next) {
          usersViewsMiddleware(next);
        },
      },
      {
        path: "transactions",
        name: RouteName.WALLET_TRANSACTIONS,
        component: WalletView,
        beforeEnter(to, from, next) {
          usersViewsMiddleware(next);
        },
      },
    ],
  },
  {
    path: "/trade",
    name: RouteName.TRADE,
    component: TradeView,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: isAuth() ? RouteName.WALLET : RouteName.HOME },
  },
  {
    path: "/wallet/Container.wasm",
    redirect: { name: RouteName.WALLET },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
