import { defineStore } from "pinia";
import { ref } from "vue";
import { MobileTabs } from "@/store/types/mobileNav";
export const useTabletNavStore = defineStore("tabletNav", () => {
  // STATE
  const currentTab = ref<MobileTabs>(MobileTabs.CHART);

  // ACTIONS
  const setTab = (tab: MobileTabs): void => {
    currentTab.value = tab;
  };
  const isCurrentTab = (tab: MobileTabs): boolean => currentTab.value === tab;

  return {
    currentTab,
    isCurrentTab,
    setTab,
  };
});
