import { defineStore, storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { Order, OrderInfoType } from "@/store/types";
import RestApiWrapper from "@/core/Api";
import {
  addOrderInClosedSessionStorage,
  addOrderInSessionStorage,
  parseOrder,
  parseOrders,
} from "@/store/utils/orders";
import { useBlockchainStore } from "@/store/blockchain";
import { OrderStatus } from "@/models/orders";
import { useAuthStore } from "@/store/auth";
import { TrDomDescription } from "@/core/types";

export const useOrdersStore = defineStore("orders", () => {
  // BLOCKCHAIN STORE
  const blockchainStore = useBlockchainStore();
  const { getTokenByParam } = blockchainStore;
  const { currentPair, assets } = storeToRefs(blockchainStore);
  // AUTH STORE
  const authStore = useAuthStore();
  const { keyPairs } = storeToRefs(authStore);

  // STATE
  const orders = ref<OrderInfoType>({
    active: [],
    history: [],
    wallets: [],
  });

  // GETTERS
  const activeOrders = computed<Order[]>(() =>
    orders.value.active.filter(
      (el: Order) =>
        el.desiredToken === currentPair.value.firstToken &&
        el.status === OrderStatus.ACTIVE
    )
  );
  const historyOrders = computed<Order[]>(() =>
    orders.value.history.filter(
      (el: Order) => el.desiredToken === currentPair.value.secondToken
    )
  );
  const activeWalletOrders = computed<Order[]>(() =>
    orders.value.wallets.filter(
      (el) =>
        el.status === OrderStatus.ACTIVE || el.status === OrderStatus.PENDING
    )
  );
  const historyWalletOrders = computed<Order[]>(() =>
    orders.value.wallets.filter(
      (el) =>
        el.status === OrderStatus.CANCELED ||
        el.status === OrderStatus.EXECUTED ||
        el.status === OrderStatus.CLOSE_PENDING
    )
  );

  // ACTIONS
  const getActiveOrders = async (): Promise<void> => {
    const firstTokenCode =
      getTokenByParam("name", currentPair.value.firstToken || "")?.code || "0";
    const secondTokenCode =
      getTokenByParam("name", currentPair.value.secondToken || "")?.code || "0";
    RestApiWrapper.getTransactionDOM({
      limit: 50,
      status: OrderStatus.ACTIVE,
      dom1: firstTokenCode,
      dom2: secondTokenCode,
    }).then(({ result: { transactions } }) => {
      orders.value.active = parseOrders(transactions, assets.value);
    });
  };
  const getHistoryOrders = async (): Promise<void> => {
    const firstTokenCode =
      (getTokenByParam("name", currentPair.value.firstToken || "") || {})
        .code || "0";
    const secondTokenCode =
      (getTokenByParam("name", currentPair.value.secondToken || "") || {})
        .code || "0";
    const executedTr = await RestApiWrapper.getTransactionDOM({
      limit: 50,
      status: OrderStatus.EXECUTED,
      dom1: firstTokenCode,
      dom2: secondTokenCode,
    });
    const canceledTt = await RestApiWrapper.getTransactionDOM({
      limit: 50,
      status: OrderStatus.CANCELED,
      dom1: firstTokenCode,
      dom2: secondTokenCode,
    });
    orders.value.history = parseOrders(
      [
        ...(executedTr?.result?.transactions || []),
        ...(canceledTt?.result?.transactions || []),
      ],
      assets.value
    );
  };

  const getWalletOrders = async (): Promise<void> => {
    const data = await RestApiWrapper.getTransactionDOM({
      limit: 100,
      wallet: keyPairs.value.public_key,
    });
    orders.value.wallets = parseOrders(
      data?.result?.transactions,
      assets.value
    );
  };
  const addWalletOrder = (
    descriptionTr: TrDomDescription,
    action: OrderStatus.PENDING
  ): void => {
    const parsedOrder: Order = parseOrder(
      {
        description: { ...descriptionTr, status: action },
        type: "approved",
        "start-desired": +descriptionTr["desired-coin"][0].amount,
        "start-offered": +descriptionTr["offered-coin"][0].amount,
        digest: "",
        ID: "10000000000000",
      },
      assets.value
    );
    addOrderInSessionStorage(parsedOrder);
    orders.value.wallets = [parsedOrder, ...orders.value.wallets];
  };
  const moveWalletTransactionToClose = (
    closeOrder: TrDomDescription,
    curOrder: Order
  ): void => {
    const newClosedOrder = {
      ...curOrder,
      seed: closeOrder.seed,
      status: OrderStatus.CLOSE_PENDING,
      time: 10000000000000,
    };
    orders.value.wallets = [
      newClosedOrder,
      ...orders.value.wallets.filter((el) => el.seed !== curOrder.seed),
    ];
    addOrderInSessionStorage(newClosedOrder);
    addOrderInClosedSessionStorage(curOrder.seed);
  };
  return {
    orders,
    activeOrders,
    historyOrders,
    activeWalletOrders,
    historyWalletOrders,
    getActiveOrders,
    getHistoryOrders,
    getWalletOrders,
    addWalletOrder,
    moveWalletTransactionToClose,
  };
});
