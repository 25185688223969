import { defineStore, storeToRefs } from "pinia";
import { useWalletStore } from "@/store/wallet";
import { useBlockchainStore } from "@/store/blockchain";
import { isAuth } from "@/core/utils/auth";
import { ref, watch } from "vue";
import { useOrdersStore } from "@/store/orders";
import { useAuthStore } from "@/store/auth";
import { useSettingsStore } from "@/store/settings";
export const useMainStore = defineStore("main", () => {
  const isLoaded = ref<boolean>(false);
  // AUTH STORE
  const authStore = useAuthStore();
  const { initPasswordHashed } = authStore;
  // SETTINGS STORE
  const settingsStore = useSettingsStore();
  const { initSettings } = settingsStore;
  // WALLET STORE
  const walletStore = useWalletStore();
  const { getWalletInfo, getWalletTransactions } = walletStore;
  // ORDERS STORE
  const ordersStore = useOrdersStore();
  const { getActiveOrders, getHistoryOrders, getWalletOrders } = ordersStore;
  // BLOCKCHAIN STORE
  const blockchainStore = useBlockchainStore();
  const { currentPair } = storeToRefs(blockchainStore);
  const { getBlockchainInfo, getCurrentTokenInfo, getHistoryTokenInfo } =
    blockchainStore;

  // Update data
  const updateTradeData = async (init?: boolean) => {
    if (init) {
      initSettings();
      initPasswordHashed();
      await getBlockchainInfo();
      watch(currentPair, () => {
        updateTradeData();
      });
      isLoaded.value = true;
      return;
    }
    if (isAuth()) {
      getWalletInfo();
      getWalletTransactions();
    }
    getCurrentTokenInfo();
    getHistoryTokenInfo();
    getActiveOrders();
    getHistoryOrders();
    getWalletOrders();
  };

  return { updateTradeData, isLoaded };
});
