import { subscribeOnStream, unsubscribeFromStream } from "./streaming";
import RestApiWrapper from "@/core/Api";
import { getAssets, checkAssetBar } from "./helpers";
const { SESSION_STORAGE, TOKENS_INFO } = require("../../../../core/constants");

const lastBarsCache = new Map();

const configurationData = {
  exchanges: [],
  symbols_types: [
    {
      name: "crypto",
      value: "",
    },
  ],
  supported_resolutions: [
    "1",
    "5",
    "15",
    "30",
    "60",
    "360",
    "720",
    "1D",
    "1W",
    "12M",
  ],
  supports_time: true,
};

export default {
  onReady: (callback) => {
    console.log("[onReady]: Method call");
    setTimeout(() => callback(configurationData));
  },

  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {
    const assets = await getAssets();
    const symbolItem = assets.find(
      ({ "asset-id": id }) =>
        TOKENS_INFO[id].name.toUpperCase() === symbolName.toUpperCase()
    );
    if (!symbolItem) {
      console.log("[resolveSymbol]: Cannot resolve symbol", symbolName);
      onResolveErrorCallback("cannot resolve symbol");
      return;
    }
    const precision = 1000;
    const symbol = {
      ticker: TOKENS_INFO[symbolItem["asset-id"]].name.toUpperCase(),
      name: TOKENS_INFO[symbolItem["asset-id"]].name.toUpperCase(),
      description: TOKENS_INFO[symbolItem["asset-id"]].description,
      type: "index",
      session: "24x7",
      timezone: "Etc/UTC",
      minmov: 1,
      pricescale: precision,
      has_intraday: true,
      has_emtpy_bars: true,
      has_no_volume: false,
      intraday_multipliers: ["1"],
      supported_resolutions: configurationData.supported_resolutions,
      data_status: "streaming",
    };
    console.log("[resolveSymbol]: Symbol resolved", symbolName);
    onSymbolResolvedCallback(symbol);
  },

  getBars: async (
    { ticker: symbol, full_name },
    resolution,
    { firstDataRequest },
    onHistoryCallback,
    onErrorCallback
  ) => {
    const assets = await getAssets();
    const asset = assets.find(
      (el) =>
        TOKENS_INFO[el["asset-id"]].name.toLowerCase() === symbol.toLowerCase()
    );
    const symbolCode = asset["asset-id"];
    console.log("[getBars]: Method call", symbol);
    const secondToken = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE.currentPair)
    ).secondToken;
    try {
      if (resolution === "1") {
        const data = (
          await RestApiWrapper.getHistoryCourseByAssetCode({
            period: "now",
            assetCode: +symbolCode,
          })
        ).result.courses;
        if (!(Array.isArray(data) && data.length && firstDataRequest)) {
          onHistoryCallback([], {
            noData: true,
          });
          return;
        }
        let bars = [];
        data.forEach((bar) => {
          if (Array.isArray(bar) && bar.length) {
            let currentTokenInfo = bar.find((el) =>
              checkAssetBar(el, symbol, secondToken)
            );
            if (!currentTokenInfo || !+currentTokenInfo["course"]) return;
            let getParsedTime = +currentTokenInfo.timestamp * 1000;
            bars = [
              ...bars,
              {
                time: getParsedTime,
                low: +currentTokenInfo["course"],
                high: +currentTokenInfo["course"],
                open: +currentTokenInfo["course"],
                close: +currentTokenInfo["course"],
                volume: +currentTokenInfo["course"],
              },
            ];
          }
        });
        if (firstDataRequest) {
          lastBarsCache.set(full_name, {
            ...bars[bars.length - 1],
          });
        }
        console.log(`[getBars]: returned ${bars.length} bar(s)`);
        onHistoryCallback(bars, { noData: false });
      } else {
        const {
          result: { trading: data },
        } = await RestApiWrapper.getHistoryTrading("year");
        if (!(Array.isArray(data) && data.length && firstDataRequest)) {
          onHistoryCallback([], {
            noData: true,
          });
          return;
        }
        let bars = [];
        data.forEach((bar) => {
          if (Array.isArray(bar[""]) && bar[""].length) {
            let currentTokenInfo = bar[""].find((el) =>
              checkAssetBar(el, symbol, secondToken)
            );
            if (!currentTokenInfo || !+currentTokenInfo["open-price"]) return;
            let getParsedTime = +bar.timestamp * 1000;
            bars = [
              ...bars,
              {
                time: getParsedTime,
                low: +currentTokenInfo["min-price"],
                high: +currentTokenInfo["max-price"],
                open: +currentTokenInfo["open-price"],
                close: +currentTokenInfo["close-price"],
                volume: +currentTokenInfo["turnover"],
              },
            ];
          }
        });
        if (firstDataRequest) {
          lastBarsCache.set(full_name, {
            ...bars[bars.length - 1],
          });
        }

        console.log(`[getBars]: returned ${bars.length} bar(s)`);
        onHistoryCallback(bars, { noData: false });
      }
    } catch (error) {
      console.log("[getBars]: Get error", error);
      onErrorCallback(error);
    }
  },

  searchSymbols: async (
    userInput,
    exchange,
    symbolType,
    onResultReadyCallback
  ) => {
    console.log("[searchSymbols]: Method call");
    const symbols = (await RestApiWrapper.getAssetList()).result[
      "asset-list"
    ].map((item) => {
      return {
        symbol: item["asset-name"].toUpperCase(),
        full_name: item["asset-name"].toUpperCase(),
        description: item["asset-description"],
        type: "index",
      };
    });
    const newSymbols = symbols.filter((symbol) => {
      const isExchangeValid = exchange === "" || symbol.exchange === exchange;
      const isFullSymbolContainsInput =
        symbol.full_name.toUpperCase().indexOf(userInput.toUpperCase()) !== -1;
      return isExchangeValid && isFullSymbolContainsInput;
    });
    onResultReadyCallback(newSymbols);
  },

  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) => {
    console.log(
      "[subscribeBars]: Method call with subscribeUID:",
      subscribeUID
    );
    subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback,
      lastBarsCache.get(symbolInfo.full_name)
    );
  },

  unsubscribeBars: (subscriberUID) => {
    console.log(
      "[unsubscribeBars]: Method call with subscriberUID:",
      subscriberUID
    );
    unsubscribeFromStream(subscriberUID);
  },
};
