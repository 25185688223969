const { WS_SERVER, TOKENS_INFO } = require("@/core/constants");
const socket = new WebSocket(WS_SERVER);
const channelToSubscription = new Map();
let currentToken;

socket.onopen = function () {
  console.log("[socket] Connected");
};

socket.onclose = function (event) {
  if (event.wasClean) {
    console.log(
      `[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`
    );
  } else {
    console.error("[close] Соединение прервано");
  }
};

socket.onerror = function (error) {
  console.log(`[error] ${error.message}`);
};

socket.onmessage = function (event) {
  let data = JSON.parse(event.data).result.courses;
  if (Array.isArray(data) && data.length) {
    let currentTokenInfo = data.find((el) => {
      const baseSymbol = Object.values(TOKENS_INFO).find(
        (el) => el.name === currentToken.toLowerCase()
      ).baseName;
      return el["first-token"] === baseSymbol;
    });
    if (!currentTokenInfo) return;
    const subscriptionItem = channelToSubscription.get(currentToken);
    if (subscriptionItem === undefined) {
      return;
    }
    let tradeTime = +currentTokenInfo.timestamp * 1000;
    const lastDailyBar = subscriptionItem.lastDailyBar;
    const nextDailyBarTime = getNextDailyBarTime(lastDailyBar.time);
    let barInfo = {
      low: +currentTokenInfo["course"],
      high: +currentTokenInfo["course"],
      open: +currentTokenInfo["course"],
      close: +currentTokenInfo["course"],
    };
    let bar;
    if (tradeTime >= nextDailyBarTime) {
      bar = {
        time: tradeTime,
        ...barInfo,
      };
      console.log("[socket] Generate new bar", bar);
    } else {
      bar = {
        ...lastDailyBar,
        high: Math.max(lastDailyBar.high, barInfo.high),
        low: Math.min(lastDailyBar.low, barInfo.low),
        close: barInfo.close,
      };
      console.log("[socket] Update the latest bar by price", bar);
    }
    subscriptionItem.lastDailyBar = bar;

    subscriptionItem.handlers.forEach((handler) => handler.callback(bar));
  }
};
let curResolution;
function getNextDailyBarTime(barTime) {
  const date = +barTime;
  if (curResolution === "1") return date + 60000;
  return date + 1440 * 60000;
}

export function subscribeOnStream(
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscribeUID,
  onResetCacheNeededCallback,
  lastDailyBar
) {
  const symbol = symbolInfo.full_name;
  currentToken = symbol;
  curResolution = resolution;
  const handler = {
    id: subscribeUID,
    callback: onRealtimeCallback,
  };
  let subscriptionItem = channelToSubscription.get(symbol);
  if (subscriptionItem) {
    subscriptionItem.handlers.push(handler);
    return;
  }
  subscriptionItem = {
    subscribeUID,
    resolution,
    lastDailyBar,
    handlers: [handler],
  };
  channelToSubscription.set(symbol, subscriptionItem);
  console.log("[subscribeBars]: Subscribe to streaming. Channel:", symbol);
  socket.send(resolution === "1D" ? "year" : "now");
}

export function unsubscribeFromStream(subscriberUID) {
  // find a subscription with id === subscriberUID
  for (const channelString of channelToSubscription.keys()) {
    const subscriptionItem = channelToSubscription.get(channelString);
    const handlerIndex = subscriptionItem.handlers.findIndex(
      (handler) => handler.id === subscriberUID
    );

    if (handlerIndex !== -1) {
      // remove from handlers
      subscriptionItem.handlers.splice(handlerIndex, 1);

      if (subscriptionItem.handlers.length === 0) {
        // unsubscribe from the channel, if it was the last handler
        //console.log('[unsubscribeBars]: Unsubscribe from streaming. Channel:', channelString);
        channelToSubscription.delete(channelString);
        break;
      }
    }
  }
}
