const { NODE } = require("./constants");
import { TrDomApi } from "@/core/types";

export interface TrDOMParams {
  wallet?: string;
  block_from?: string;
  block_to?: string;
  dom1?: string;
  dom2?: string;
  status?: string;
  limit?: string | number;
  offset?: string | number;
}
export default {
  _getResponseData(res: Response) {
    if (!res.ok) {
      return Promise.reject(`Ошибка: ${res.status}`);
    }
    return res.json();
  },

  getBlockchainInfo() {
    const params = {
      jsonrpc: "2.0",
      method: "get-blockchain-info",
      id: 3,
      version: "1.0",
    };
    return fetch(NODE, {
      method: "POST",
      body: JSON.stringify(params),
    }).then((res) => {
      return res.json();
    });
  },

  getWalletByAddress(public_key: string) {
    const params = {
      jsonrpc: "2.0",
      method: "get-wallet-state",
      params: {
        "public-key": public_key,
      },
      id: 3,
      version: "1.0",
    };
    return fetch(NODE, {
      method: "POST",
      body: JSON.stringify(params),
    }).then((res) => {
      return this._getResponseData(res);
    });
  },

  getTransactionFromWallet(address: string, count = 25, trCode = 0) {
    const params = {
      jsonrpc: "2.0",
      method: "get-transactions-from-wallet",
      params: {
        wallet: address,
        "count-transaction": count,
        "code-transaction": trCode,
      },
      id: 1,
      version: "1.0",
    };
    return fetch(NODE, {
      method: "POST",
      body: JSON.stringify(params),
    }).then((res) => {
      return this._getResponseData(res);
    });
  },

  getTransactionToWallet(address: string, count = 25, trCode = 0) {
    const params = {
      jsonrpc: "2.0",
      method: "get-transactions-to-wallet", //EX get-dom-transaction
      params: {
        wallet: address,
        "count-transaction": count,
        "code-transaction": trCode,
        status: "Active",
      },
      id: 1,
      version: "1.0",
    };
    return fetch(NODE, {
      method: "POST",
      body: JSON.stringify(params),
    }).then((res) => {
      return this._getResponseData(res);
    });
  },

  /*get current courses */
  getCourses() {
    const params = {
      jsonrpc: "2.0",
      method: "get-courses",
      params: {
        period: "now",
      },
      id: 1,
      version: "1.0",
    };
    return fetch(NODE, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }).then((res) => {
      return this._getResponseData(res);
    });
  },

  /*get history trading, period = now,day,week,month,halfyear,year  */
  getHistoryTrading(period: string) {
    const params = {
      jsonrpc: "2.0",
      method: "get-history-trading",
      params: {
        period: period,
      },
      id: 1,
      version: "1.0",
    };

    return fetch(NODE, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }).then((res) => {
      return this._getResponseData(res);
    });
  },

  sendTransaction(parceTransactionBody: any) {
    const params = {
      jsonrpc: "2.0",
      method: "send-transaction",
      params: parceTransactionBody,
      id: 3,
      version: "1.0",
    };

    return fetch(NODE, {
      method: "POST",
      body: JSON.stringify(params),
    }).then((res) => {
      return this._getResponseData(res);
    });
  },

  getLastBlockId() {
    const params = {
      jsonrpc: "2.0",
      method: "get-current-block-id",
      params: {},
      id: 3,
      version: "1.0",
    };

    return fetch(NODE, {
      method: "POST",
      body: JSON.stringify(params),
    }).then((res) => {
      return this._getResponseData(res);
    });
  },

  /*get history trading, period = now,day,week,month,halfyear,year  */
  getHistoryCourseByAssetCode({
    period,
    assetCode,
  }: {
    period: string;
    assetCode: string;
  }) {
    const params = {
      jsonrpc: "2.0",
      method: "get-history-courses",
      params: {
        period: period,
        code: assetCode,
      },
      id: 1,
      version: "1.0",
    };

    return fetch(NODE, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }).then((res) => {
      return this._getResponseData(res);
    });
  },
  getTransactionDOM({
    wallet,
    block_from,
    block_to,
    dom1,
    dom2,
    limit,
    offset,
    status,
  }: TrDOMParams): Promise<{ result: { transactions: TrDomApi[] } }> {
    const params = {
      jsonrpc: "2.0",
      method: "get-dom-transaction",
      params: {
        wallet,
        dom1,
        dom2,
        offset,
        status,
        ["block-from"]: block_from,
        ["block-to"]: block_to,
        ["count-transaction"]: limit,
      },
      id: 1,
      version: "1.0",
    };

    return fetch(NODE, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }).then((res) => {
      return this._getResponseData(res);
    });
  },
};
