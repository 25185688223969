import { defineStore } from "pinia";
import { ref } from "vue";
const SETTINGS_KEY = "settings";
type Settings = {
  orderConfirm: boolean;
  cancelConfirm: boolean;
};
export const useSettingsStore = defineStore("settings", () => {
  // STATE
  const settings = ref<Settings>({
    orderConfirm: true,
    cancelConfirm: true,
  });

  // ACTIONS
  const setOrderConfirmState = (state: boolean): void => {
    settings.value.orderConfirm = state;
    saveInLocalStorage();
  };
  const setCancelConfirmState = (state: boolean): void => {
    settings.value.cancelConfirm = state;
    saveInLocalStorage();
  };
  const saveInLocalStorage = () => {
    localStorage.setItem(SETTINGS_KEY, JSON.stringify(settings.value));
  };
  const initSettings = () => {
    const storageItem = localStorage.getItem(SETTINGS_KEY);
    if (storageItem) settings.value = JSON.parse(storageItem);
  };

  return {
    settings,
    setCancelConfirmState,
    setOrderConfirmState,
    initSettings,
  };
});
