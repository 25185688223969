export interface KeyPairsType {
  public_key: string;
  private_key: string;
}
export enum AuthType {
  REGISTRATION = "registration",
  RESTORE = "restore",
  EXISTING = "existing",
}
export interface ContainerRowDataType {
  meta: {
    [key: string]: string;
  };
  rawdata: string;
}
