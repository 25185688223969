export default (position: string): string => {
  const posArr: string[] = position.split(" ");
  if (posArr.length !== 2) {
    console.error(
      "Missing position param or invalid quantity! Set default position(right bottom)"
    );
    return "right-bottom";
  }
  let posX = "right";
  let posY = "top";
  if (posArr.includes("right")) posX = "right";
  else if (posArr.includes("left")) posX = "left";
  else if (posArr.includes("center")) posX = "center";

  if (posArr.includes("top")) posY = "top";
  else if (posArr.includes("bottom")) posY = "bottom";
  return `${posX}-${posY}`;
};
