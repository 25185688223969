import { createApp } from "vue";
import { createPinia } from "pinia";
import { VueQueryPlugin } from "vue-query";
import { VClickOutside } from "@gaus/ui";
import App from "./App.vue";
import router from "./router";
import i18n from "./plugins/i18n";
import "@/assets/scss/top.scss";

const app = createApp(App);

// GLOBAL COMPONENTS
import VInput from "@/components/ui-kit/v-input.vue";
import VCheckSelect from "@/components/ui-kit/v-check-select.vue";
import VRadioSelect from "@/components/ui-kit/v-radio-select.vue";
app.component("v-input", VInput);
app.component("v-check-select", VCheckSelect);
app.component("v-radio-select", VRadioSelect);

app
  .directive("click-outside", VClickOutside)
  .use(VueQueryPlugin)
  .use(i18n)
  .use(router)
  .use(createPinia())
  .mount("#app");
