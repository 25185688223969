export const tableColumns = (
  firstToken: string | null,
  secondToken: string | null,
  isRevert: boolean
) => [
  {
    label: `Rate (${(
      (isRevert ? firstToken : secondToken) || ""
    ).toUpperCase()})`,
    field: "rate",
    sortable: false,
    width: "37%",
    headerStyles: { fontSize: "12px" },
  },
  {
    label: `Sell (${(secondToken || "").toUpperCase()})`,
    field: "sell",
    width: "31%",
    sortable: false,
    headerStyles: { fontSize: "12px" },
  },
  {
    label: `Buy (${(firstToken || "").toUpperCase()})`,
    field: "buy",
    width: "31%",
    sortable: false,
    headerStyles: { justifyContent: "flex-end", fontSize: "12px" },
    columnStyles: { justifyContent: "flex-end" },
  },
];
