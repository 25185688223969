import { ref, computed } from "vue";
import moment from "moment";
export const useTransactionFilter = (
  period: string[],
  token: string[],
  type: any
) => {
  // STATE
  const curPeriod = ref<string[]>(period);
  const curToken = ref<string[]>(token);
  const curType = ref<any>({ ...type });

  const isOpenedCalendar = ref<boolean>(false);

  // COMPUTED
  const curPeriodLabel = computed<string>(() => {
    if (curPeriod.value.length)
      return curPeriod.value
        .map((el) => moment(el).format("DD.MM.YY"))
        .join(" - ");
    return "dd.mm.yy – dd.mm.yy";
  });
  const disabledBtn = computed<boolean>(
    () =>
      JSON.stringify(curPeriod.value) === JSON.stringify(period) &&
      JSON.stringify(curToken.value) === JSON.stringify(token) &&
      curType.value.withdraw === type.withdraw &&
      curType.value.transfer === type.transfer
  );
  const isHideClearBtn = computed<boolean>(() => {
    return Boolean(
      curPeriod.value.length ||
        curToken.value.length ||
        curType.value.withdraw ||
        curType.value.transfer
    );
  });
  // METHODS
  const resetFilters = () => {
    curType.value.withdraw = false;
    curType.value.transfer = false;
    curPeriod.value = [];
    curToken.value = [];
  };

  const handleChangeWithdraw = ($event: boolean) => {
    if ($event && curType.value.transfer) curType.value.transfer = false;
  };
  const handleChangeTransfer = ($event: boolean) => {
    if ($event && curType.value.withdraw) curType.value.withdraw = false;
  };

  return {
    disabledBtn,
    isOpenedCalendar,
    curPeriodLabel,
    resetFilters,
    isHideClearBtn,
    curType,
    curPeriod,
    curToken,
    handleChangeWithdraw,
    handleChangeTransfer,
  };
};
