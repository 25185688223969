import { computed } from "vue";
import { storeToRefs } from "pinia";
import { tableColumns } from "@/components/_blocks/Order/share/book/constants";
import { useBlockchainStore } from "@/store/blockchain";
import { Order } from "@/store/types";
import { useOrderFormStore } from "@/store/orderForm";
import { useAuthStore } from "@/store/auth";
import { useWalletStore } from "@/store/wallet";

export const useOrderBook = () => {
  // BLOCKCHAIN STORE
  const blockchainStore = useBlockchainStore();
  const { currentPair, isRevertRate } = storeToRefs(blockchainStore);
  // ORDER FORM STORE
  const orderFormStore = useOrderFormStore();
  const { setSelectedOrder } = orderFormStore;
  // AUTH STORE
  const authStore = useAuthStore();
  const { keyPairs } = storeToRefs(authStore);
  // WALLET STORE
  const walletStore = useWalletStore();
  const { getBalanceByTokenParam } = walletStore;

  const firstTokenBalance = computed(() =>
    getBalanceByTokenParam(currentPair.value.firstToken || "", "name")
  );

  const columns = computed(() =>
    tableColumns(
      currentPair.value.firstToken,
      currentPair.value.secondToken,
      isRevertRate.value
    )
  );

  // METHODS
  const isFixedAndMoreThanMyBalance = (order: Order) => {
    return (
      order.type === "isFixed" &&
      +order.curDesired! > +firstTokenBalance.value.available
    );
  };
  const isMyOrder = (address: string): boolean =>
    keyPairs.value.public_key === address;
  const handleClickRow = (order: Order) => {
    if (isMyOrder(order.address)) return;
    setSelectedOrder(order);
  };

  return {
    columns,
    handleClickRow,
    isMyOrder,
    isFixedAndMoreThanMyBalance,
  };
};
