import RestApiWrapper from "@/core/Api";
const { TOKENS_INFO } = require("../../../../core/constants");

export const getAssets = async () => {
  if (window["supported-assets"]) {
    return window["supported-assets"];
  }
  const blockchainInfo = await RestApiWrapper.getBlockchainInfo();
  const assets = blockchainInfo.result["supported-assets"];
  window["supported-assets"] = assets;
  return assets;
};

export const checkAssetBar = (el, symbol, secondToken) => {
  const baseSymbol = Object.values(TOKENS_INFO).find(
    (el) => el.name === symbol.toLowerCase()
  ).baseName;
  const baseSecondToken = Object.values(TOKENS_INFO).find(
    (el) => el.name === secondToken.toLowerCase()
  ).baseName;
  return (
    el["first-token"] === baseSymbol && el["second-token"] === baseSecondToken
  );
};
