import RestApiWrapper from "@/core/Api";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Shampoox from "@/components/wasm/shampoox";
import { OrderForm } from "@/components/_blocks/Order/types";
import { KeyPairsType } from "@/store/types";
import { TrDomDescription } from "@/core/types";

export const SendTransactionWasm = (
  form: OrderForm,
  { private_key, public_key }: KeyPairsType,
  { ownedCode, desiredCode }: { ownedCode: string; desiredCode: string },
  successCallback: (order: TrDomDescription) => void,
  errorCallback: () => void
) => {
  RestApiWrapper.getLastBlockId()
    .then((res) => {
      Shampoox().then(async (api: any) => {
        const transaction_id = 1;
        const transaction = new api.Transaction();
        const error = new api.Error();
        const Pairs = new api.Pair.fromPrivateKey(private_key, error);
        const orderType = form.isIndivisible ? 2 : 3; // isFixed, isDivisible

        transaction.CAEB(
          Pairs,
          String(res["result"]["current-block-id"]),
          String(transaction_id),
          String(transaction_id),
          String(res["result"]["current-block-id"]),
          public_key,
          0,
          Number(desiredCode),
          String(form.sell),
          0,
          Number(ownedCode),
          String(form.buy),
          orderType,
          4000,
          0.0,
          "",
          error
        );
        RestApiWrapper.sendTransaction(JSON.parse(transaction.body)).then(
          (res2) => {
            if (res2.result === "true") {
              successCallback(JSON.parse(transaction.body));
            } else {
              errorCallback();
            }
          }
        );
      });
    })
    .catch(() => {
      errorCallback();
    });
};
