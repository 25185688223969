import { ref, watch, computed } from "vue";

export const useOrderBookFilter = (
  isIndivisible: boolean,
  isUngrouping: boolean
) => {
  // STATE
  const curIsIndivisible = ref<boolean>(isIndivisible);
  const curIsUngrouping = ref<boolean>(isUngrouping);
  const activeUngroupTooltip = ref<boolean>(false);

  watch(curIsUngrouping, () => {
    if (curIsUngrouping.value) curIsIndivisible.value = false;
  });
  watch(curIsIndivisible, () => {
    if (curIsIndivisible.value) curIsUngrouping.value = false;
  });

  // COMPUTED
  const disabledBtn = computed<boolean>(
    () =>
      curIsIndivisible.value === isIndivisible &&
      curIsUngrouping.value === isUngrouping
  );
  const visibleClear = computed<boolean>(
    () => curIsIndivisible.value || curIsUngrouping.value
  );
  // METHODS
  const resetFilters = () => {
    curIsIndivisible.value = curIsUngrouping.value = false;
  };

  return {
    disabledBtn,
    curIsIndivisible,
    curIsUngrouping,
    resetFilters,
    visibleClear,
    activeUngroupTooltip,
  };
};
