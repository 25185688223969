export const desktopColumns = (token: string) => [
  {
    label: "Token",
    field: "fullname",
    width: "20%",
    sortable: true,
    isKey: true,
  },
  {
    label: `Rate (${token.toUpperCase()})`,
    field: "price",
    width: "16%",
    sortable: true,
  },
  {
    label: "24h Change",
    field: "change",
    width: "16%",
    sortable: true,
  },
  {
    label: "24h High",
    field: "high",
    width: "16%",
    sortable: true,
  },
  {
    label: "24h Low",
    field: "low",
    width: "16%",
    sortable: true,
  },
  {
    label: "24h Turnover",
    field: "turnover",
    width: "16%",
    sortable: true,
  },
];
export const notebookColumns = (token: string) => [
  {
    label: "Token",
    field: "fullname",
    width: "25%",
    sortable: true,
    isKey: true,
  },
  {
    label: `Rate (${token.toUpperCase()})`,
    field: "price",
    width: "25%",
    sortable: true,
  },
  {
    label: "24h Change",
    field: "change",
    width: "25%",
    sortable: true,
  },
  {
    label: "24h Turnover",
    field: "turnover",
    width: "25%",
    sortable: true,
  },
];
export const tabletColumns = () => [
  {
    label: "Token",
    field: "fullname",
    width: "50%",
    sortable: true,
    isKey: true,
  },
  {
    label: "24h Change / Price",
    field: "mobileRight",
    width: "50%",
    sortable: false,
    headerStyles: { justifyContent: "flex-end" },
    columnStyles: { justifyContent: "flex-end" },
  },
];
