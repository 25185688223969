const {
  USDT_BASE_NAMES,
  USDT_NAMES,
  WITHDRAW_FEE,
} = require("@/core/constants");

export interface SearchParamsType {
  // eslint-disable-next-line
  beforeSearchCallback?: (data: any[]) => any;
  offset?: number;
  limit?: number;
  sortBy?: string;
  searchBy?: string;
  sort?: string;
  search?: string;
  // eslint-disable-next-line
  afterSearchCallback?: (data: any[]) => any;
}

export const filterData = (
  // eslint-disable-next-line
  data: any,
  {
    sortBy,
    sort,
    search,
    afterSearchCallback,
    beforeSearchCallback,
    searchBy,
    offset,
    limit,
  }: SearchParamsType
) => {
  let total = data.length;
  let filteredData = [...data];
  if (beforeSearchCallback) {
    filteredData = beforeSearchCallback(filteredData);
    total = filteredData.length;
  }
  filteredData = searchData(filteredData, { searchBy, search });
  if (search && searchBy) total = filteredData.length;
  filteredData = sortData(filteredData, { sort, sortBy });
  filteredData = paginateData(filteredData, { offset, limit });
  if (afterSearchCallback) filteredData = afterSearchCallback(filteredData);
  return { data: filteredData, total };
};

export const searchData = (
  data: any,
  { searchBy, search }: { searchBy?: string; search?: string }
) => {
  let searchedData = [...data];
  if (search && searchBy) {
    searchedData = searchedData.filter((el) => {
      return String(el[searchBy])
        .toLowerCase()
        .includes(String(search).toLowerCase());
    });
  }
  return searchedData;
};

export const paginateData = (
  data: any,
  { offset, limit }: { offset?: number; limit?: number }
) => {
  let searchedData = [...data];
  if (
    offset !== undefined &&
    limit !== undefined &&
    !isNaN(offset) &&
    !isNaN(limit)
  ) {
    searchedData = searchedData.slice(offset, offset + limit);
  }
  return searchedData;
};

export const sortData = (
  // eslint-disable-next-line
  data: any,
  { sortBy, sort }: { sortBy?: string; sort?: string }
) => {
  const sortedData = [...data];
  if (sort && sortBy) {
    const collator = new Intl.Collator();
    sortedData.sort((prev, next) => {
      const prevParam = prev[sortBy];
      const nextParam = next[sortBy];
      if (isNaN(prevParam) || isNaN(nextParam)) {
        return sort === "asc"
          ? collator.compare(nextParam, prevParam)
          : collator.compare(prevParam, nextParam);
      }
      if (+prevParam < +nextParam) return sort === "asc" ? -1 : 1;
      if (+prevParam > +nextParam) return sort === "asc" ? 1 : -1;
      return 0;
    });
  }
  return sortedData;
};

export const getTokenName = (token: string, base?: boolean): string => {
  if (base) return USDT_BASE_NAMES[token.toLowerCase()] || token;
  return USDT_NAMES[token.toLowerCase()] || token;
};
export const getFeeByTokenName = (token: string): number => {
  return WITHDRAW_FEE[token.toLowerCase()] || 0;
};
export const hasWithdrawByTokenName = (token: string): boolean => {
  return !!WITHDRAW_FEE[token.toLowerCase()] || false;
};
