export interface BalanceType {
  code: string;
  amount: string;
}
export type TransactionDescriptionType = {
  seed: string;
  "blockchain-id": string;
  "transaction-type": string;
  "transaction-id": string;
  from: string;
  to: string;
  asset: BalanceType[];
  description: string;
  digest: string;
  signature: string;
};
export interface TransactionApiType {
  description: TransactionDescriptionType;
  type: string;
  digest: string;
  ID: string;
  timestamp: string;
}
export enum TransactionTypeEnum {
  WITHDRAW = "withdraw",
  TRANSFER = "transfer",
}
export enum TransactionDirectionEnum {
  SENT = "sent",
  RECEIVED = "received",
}
export interface TransactionInfoType {
  date: number;
  type: TransactionTypeEnum;
  direction: TransactionDirectionEnum;
  token_name: string;
  token_fullname: string;
  token_code: string;
  amount: string;
  id: string;
  block: number;
  status: string;
  to: string;
  from: string;
  address: string;
  seed: string;
}

export enum WalletStatus {
  UNCONFIRMED = "NoKYC",
  CONFIRMED = "KYC",
  BLOCKED = "blocked",
}
export interface WalletInfoType {
  address: string;
  commission: string;
  status: WalletStatus;
  balance: BalanceType[];
  freeze: BalanceType[];
  transactions: TransactionInfoType[];
}
export interface TokenInfoType {
  token: string;
  tokenBaseName: string;
  tokenDescription?: string;
  balance: string | number;
  balanceAvailable?: string | number;
  balanceReserved?: string | number;
}
