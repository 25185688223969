import { defineStore } from "pinia";
import RestApiWrapper from "@/core/Api";
import {
  AssetsBlockchainInfoType,
  AssetsBlockchainType,
  AssetsHistory,
  CurrentPair,
  HistoryAssetsBlockchainInfo,
  HistoryItemType,
  SupportedAssetsApiType,
} from "@/store/types";
import { computed, ref } from "vue";
import {
  parseAssetsInfo,
  parseHistoryInfoAssets,
} from "@/store/utils/blockchain";
const {
  BASE_TOKEN,
  DEFAULT_TOKEN_PAIR,
  SESSION_STORAGE,
  TOKENS_INFO,
} = require("@/core/constants");

export const useBlockchainStore = defineStore("blockchain", () => {
  // STATE
  const isRevertRate = ref<boolean>(false);
  const currentPair = ref<CurrentPair>({ firstToken: null, secondToken: null });
  const assets = ref<AssetsBlockchainType[]>([]);
  const currentAssetsInfo = ref<AssetsBlockchainInfoType[]>([]);
  const historyAssetsInfo = ref<HistoryAssetsBlockchainInfo>({});

  // GETTERS
  const getListAssetsFullnames = computed<string[]>(() =>
    assets.value.map((el) => el.fullname.toUpperCase())
  );
  const getListAssetsNames = computed<string[]>(() =>
    assets.value.map((el) => el.name.toUpperCase())
  );
  const popularAssetsHistory = computed<AssetsHistory[]>(() => {
    const history: AssetsHistory[] = [];
    for (let i = 0; i < assets.value.length; i++) {
      const elem = assets.value[i];
      if (elem.name === BASE_TOKEN.toLowerCase()) continue;
      const elemInfo: AssetsBlockchainInfoType | undefined =
        currentAssetsInfo.value.find(
          (el: AssetsBlockchainInfoType) => el.name === elem.name
        );
      const historyInfo: HistoryItemType[] = historyAssetsInfo.value[elem.name];
      if (elemInfo && Array.isArray(historyInfo) && historyInfo.length) {
        history.push({
          ...elem,
          ...elemInfo,
          history: historyInfo,
        });
        if (history.length > 4) return history;
      }
    }
    return history;
  });
  const getTokenByParam = (
    param: keyof AssetsBlockchainType,
    value: string | number
  ): AssetsBlockchainType | undefined => {
    return assets.value.find((el: AssetsBlockchainType) => el[param] === value);
  };
  const getTokenInfoByParam = (
    param: keyof AssetsBlockchainType,
    value: string | number
  ): AssetsBlockchainInfoType | undefined => {
    return currentAssetsInfo.value.find(
      (el: AssetsBlockchainInfoType) =>
        String(el[param]).toLowerCase() === String(value).toLowerCase()
    );
  };
  // ACTIONS
  const getBlockchainInfo = async (): Promise<void> => {
    const { result } = await RestApiWrapper.getBlockchainInfo();
    if (result) {
      assets.value = result["supported-assets"].filter((el: SupportedAssetsApiType)=> {
        return el["asset-id"] in TOKENS_INFO
      }).map(
        (el: SupportedAssetsApiType) => {

          return {
            name: TOKENS_INFO[el["asset-id"]].name,
            fullname: TOKENS_INFO[el["asset-id"]].name,
            code: el["asset-id"],
            precision: +el["asset-precision"],
            description: TOKENS_INFO[el["asset-id"]].description,
          };
        }
      );
    }
  };
  const getCurrentTokenInfo = async (): Promise<void> => {
    const { result: resCourses } = await RestApiWrapper.getCourses();
    const { result: resHistory } = await RestApiWrapper.getHistoryTrading(
      "now"
    );
    currentAssetsInfo.value = parseAssetsInfo(
      resHistory.trading,
      resCourses.courses,
      assets.value,
      currentPair.value?.secondToken || ""
    );
  };
  const getHistoryTokenInfo = async (): Promise<void> => {
    const { result } = await RestApiWrapper.getHistoryTrading("day");
    historyAssetsInfo.value = parseHistoryInfoAssets(
      result.trading,
      assets.value,
      currentPair.value?.secondToken || ""
    );
  };
  const initCurrentPair = (): void => {
    const sessionStoragePair: string | null = sessionStorage.getItem(
      SESSION_STORAGE.currentPair
    );
    if (sessionStoragePair) {
      try {
        const parsedPair = JSON.parse(sessionStoragePair);
        if (
          getTokenByParam("name", parsedPair.firstToken) &&
          getTokenByParam("name", parsedPair.secondToken)
        )
          setCurrentPair(parsedPair);
        else
          throw new Error(
            `${parsedPair.firstToken} or ${parsedPair.secondToken} has not in blockchain`
          );
      } catch (e) {
        console.error(e);
        setCurrentPair(DEFAULT_TOKEN_PAIR);
      }
    } else {
      setCurrentPair(DEFAULT_TOKEN_PAIR);
    }
  };
  const setCurrentPair = ({ firstToken, secondToken }: CurrentPair): void => {
    currentPair.value = {
      firstToken: firstToken?.toLowerCase() || null,
      secondToken: secondToken?.toLowerCase() || null,
    };
    sessionStorage.setItem(
      SESSION_STORAGE.currentPair,
      JSON.stringify(currentPair.value)
    );
  };
  const revertCurrentPair = (): void => {
    setCurrentPair({
      firstToken: currentPair.value.secondToken,
      secondToken: currentPair.value.firstToken,
    });
  };
  const toggleRevertRate = (): void => {
    isRevertRate.value = !isRevertRate.value;
  };
  return {
    currentPair,
    assets,
    currentAssetsInfo,
    historyAssetsInfo,
    getListAssetsFullnames,
    getListAssetsNames,
    popularAssetsHistory,
    isRevertRate,
    getBlockchainInfo,
    getTokenByParam,
    getCurrentTokenInfo,
    getHistoryTokenInfo,
    initCurrentPair,
    getTokenInfoByParam,
    setCurrentPair,
    revertCurrentPair,
    toggleRevertRate,
  };
});
