const { MAX_NUMBER_AFTER_COMMA } = require("@/core/constants");

export const getRandomNumber = (min = 0, max = 10000): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const formatNumber = (
  number: number,
  {
    token,
    precision = MAX_NUMBER_AFTER_COMMA,
    fixed,
    useAbbreviate = true,
  }: {
    token?: string;
    precision?: number;
    fixed?: boolean;
    useAbbreviate?: boolean;
  } = {}
): string | number => {
  let isMillion = false;
  let isBillion = false;
  let curPrecision = precision;
  let formattedNumber: string | number = number;
  if (token && token.toLowerCase() === "btc") {
    formattedNumber = parseBTCFloat(number);
    if (isNaN(+formattedNumber)) return formattedNumber;
  }
  if (useAbbreviate) {
    if (formattedNumber >= 1000000000) {
      isBillion = true;
      curPrecision = MAX_NUMBER_AFTER_COMMA;
      formattedNumber = +formattedNumber / 1000000000;
    } else if (formattedNumber >= 1000000) {
      isMillion = true;
      curPrecision = MAX_NUMBER_AFTER_COMMA;
      formattedNumber = +formattedNumber / 1000000;
    }
  }
  formattedNumber = String(
    new Intl.NumberFormat("ru-RU", {
      minimumFractionDigits: fixed ? curPrecision : 0,
      maximumFractionDigits: curPrecision,
    }).format(+formattedNumber)
  );
  return (
    formattedNumber.replace(/,/g, ".") +
    (isMillion ? "M" : "") +
    (isBillion ? "BLN" : "")
  );
};
export const toFloatNumber = (
  number: number | string,
  precision = MAX_NUMBER_AFTER_COMMA,
  fixed = false
): string | number => {
  if (fixed) return (+number).toFixed(precision);
  return parseFloat((+number).toFixed(precision));
};
export const getNumberSymbolsAfterComma = (number: number): number => {
  const n: string = number.toString();
  if (n.indexOf("e") !== -1) return parseInt(n.split("e")[1]) * -1;
  const separator = (1.1).toString().split("1")[1];
  const parts = n.split(separator);
  return parts.length > 1 ? parts[parts.length - 1].length : 0;
};
export const parseBTCFloat = (value: number | string): string | number => {
  let fValue = parseFloat(String(value));
  if (fValue <= 0.000001 && fValue) {
    fValue = fValue * 10 ** 8;
    return `${fValue} sat`;
  }
  return value;
};
