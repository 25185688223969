export const tabletColumns = [
  {
    field: "mobileLeft",
    width: "50%",
    sortable: false,
  },
  {
    field: "mobileRight",
    width: "50%",
    sortable: false,
  },
];
export const notebookColumns = [
  {
    label: "Date / Time",
    field: "date",
    width: "25%",
    sortable: true,
  },
  {
    label: "Type",
    field: "type",
    width: "25%",
    sortable: false,
  },
  {
    label: "Token",
    field: "token_fullname",
    width: "25%",
    sortable: true,
  },
  {
    label: "Amount",
    field: "amount",
    width: "25%",
    sortable: true,
  },
];
export const desktopColumns = [
  {
    label: "Date / Time",
    field: "date",
    width: "20%",
    sortable: true,
  },
  {
    label: "Type",
    field: "type",
    width: "17.5%",
    sortable: false,
  },
  {
    label: "Token",
    field: "token_fullname",
    width: "17.5%",
    sortable: true,
  },
  {
    label: "Amount",
    field: "amount",
    width: "22.5%",
    sortable: true,
  },
  {
    label: "Address",
    field: "address",
    width: "22.5%",
    sortable: false,
  },
];
