import {
  AssetCourseApi,
  AssetHistoryApi,
  AssetsBlockchainInfoType,
  AssetsBlockchainType,
  GrowthStateType,
  HistoryAssetsBlockchainApi,
  HistoryAssetsBlockchainInfo,
} from "@/store/types";
import { toFloatNumber } from "@/core/utils/number";
const { TOKENS_INFO } = require("@/core/constants");

export const checkAssetBar = (
  el: AssetCourseApi | AssetHistoryApi,
  symbol: string,
  secondToken: string
): boolean => {
  const arrTokensInfo = Object.values(TOKENS_INFO) as {
    name: string;
    baseName: string;
  }[];
  const baseSymbol = arrTokensInfo.find(
    (el: { baseName: string; name: string }) => el.name === symbol.toLowerCase()
  )?.baseName;
  const baseSecondToken = arrTokensInfo.find(
    (el) => el.name === secondToken.toLowerCase()
  )?.baseName;
  return (
    el["first-token"] === baseSymbol && el["second-token"] === baseSecondToken
  );
};
export const parseAssetsInfo = (
  history: HistoryAssetsBlockchainApi[] | "",
  courses: AssetCourseApi[],
  assets: AssetsBlockchainType[],
  secondToken: string
): AssetsBlockchainInfoType[] => {
  return assets.map((el: AssetsBlockchainType) => {
    // Изначально установлены значения для каждого свойства
    let growth_state: GrowthStateType = "stable";
    let change: string | number = "";
    let high: string | number = "";
    let low: string | number = "";
    let turnover: string | number = "";
    let price: string | number = "";
    // Устанавливаем текущий курс
    if (Array.isArray(courses) && courses.length) {
      price =
        courses.find((item) => checkAssetBar(item, el.name, secondToken))
          ?.course || "";
    }
    // Если есть история цен для данного актива, находим необходимые значения для свойств
    if (Array.isArray(history) && history.length) {
      let historyItem: AssetHistoryApi | null = null;
      for (let i = 0; i < history.length; i++) {
        const historyItemNow = history[i];
        if (!Array.isArray(historyItemNow[""])) continue;
        historyItemNow[""].forEach((item: AssetHistoryApi) => {
          if (checkAssetBar(item, el.name, secondToken)) {
            historyItem = item;
          }
        });
        if (historyItem) break;
      }
      if (historyItem) {
        high = isNaN(+historyItem["max-price"])
          ? ""
          : +historyItem["max-price"];
        if (high < price) high = price;
        low = isNaN(+historyItem["min-price"]) ? "" : +historyItem["min-price"];
        turnover = isNaN(+historyItem["turnover"])
          ? ""
          : +historyItem["turnover"];
        // Если закрытая цена равна 0 или открытая цена равна 0, то изменение равно 0 и состояние роста установлено на основе отношения цен
        if (
          historyItem["close-price"] === "0" ||
          historyItem["open-price"] === "0"
        ) {
          change = 0;
          if (+historyItem["close-price"] > +historyItem["open-price"]) {
            growth_state = "increase";
          } else if (+historyItem["close-price"] < +historyItem["open-price"]) {
            growth_state = "decrease";
          }
        } else {
          // Если цены больше 0, то вычисляем изменение и состояние роста
          change = +toFloatNumber(
            ((+historyItem["close-price"] - +historyItem["open-price"]) /
              +historyItem["open-price"]) *
              100,
            2
          );
          if (change > 0) {
            growth_state = "increase";
          }
          if (change < 0) {
            growth_state = "decrease";
          }
        }
      }
    }
    // Возвращаем объект с данными актива и вычисленными значениями свойств
    return {
      price,
      change,
      high,
      low,
      turnover,
      growth_state,
      ...el,
    };
  });
};
export const parseHistoryInfoAssets = (
  history: HistoryAssetsBlockchainApi[],
  assets: AssetsBlockchainType[],
  secondToken: string
): HistoryAssetsBlockchainInfo => {
  const historyObj: HistoryAssetsBlockchainInfo = {};
  if (!history) return historyObj;
  assets.forEach((asset) => {
    historyObj[asset.name] = [];
    history.forEach(({ timestamp, [""]: historyItems }) => {
      const infoItem = (historyItems || [])?.find((item) =>
        checkAssetBar(item, asset.name, secondToken)
      );
      if (infoItem?.["close-price"]) {
        historyObj[asset.name].push({
          time: +timestamp,
          value: +infoItem["close-price"],
        });
      }
    });
  });
  return historyObj;
};
